export const environment = {
  production: false,
  baseHref: '/',
  redirectUri: 'https://documents.dev.ag2goservices.amgreetings.com/',
  postLogoutRedirectUri: 'https://documents.dev.ag2goservices.amgreetings.com/',
  webApi: 'https://documents-api.dev.ag2goservices.amgreetings.com',
  tablePaginationSizes: [10, 25, 100],
  pageableAllItems: 99999,
  noLoadingSpinnerHeader: 'loading-spinner-disabled',
  httpRequestChunkSize: 100,
  maxFileUploadSizeMB: 10
};
